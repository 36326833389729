import { ADD_NAVIGATION, REMOVE_NAVIGATION, RESET_NAVIGATION, RESET_INITIAL_STATE } from '../constants/index';

let INIT_STATE = {
	history: [],
};

function NavigationReducer(state = INIT_STATE, action) {
	switch (action.type) {
		case ADD_NAVIGATION:
			return {
				...state,
				history: state.history.concat(action.payload.history)
			};

		case REMOVE_NAVIGATION:

			
			return {
				...state,
				// history: state.history.splice(state.history.indexOf(action.payload.history) + 1)
				history: state.history.concat(state.history.splice(state.history.indexOf(action.payload.history), 1)[0])
			};
		case RESET_NAVIGATION:

			
			return {
				...state,
				history: ['/aura/searchstack']
			};
		case RESET_INITIAL_STATE:
			return {
				...INIT_STATE,
			};
		default:
			return state;
	}
}

export default NavigationReducer;
