//Clear Reducers

export const RESET_INITIAL_STATE = 'CLEAR_ALL_REDUCERS_DATA';

// Layout and colors CONSTANTS
export const LIGHT_THEME = 'LIGHT_THEME';
export const DARK_THEME = 'DARK_THEME';
export const THEME_COLOR = 'THEME_COLOR';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const DIRECTION = 'DIRECTION';
export const BLUE_THEME = 'BLUE_THEME';
export const GREEN_THEME = 'GREEN_THEME';
export const RED_THEME = 'RED_THEME';
export const BLACK_THEME = 'BLACK_THEME';
export const PURPLE_THEME = 'PURPLE_THEME';
export const INDIGO_THEME = 'INDIGO_THEME';
export const ORANGE_THEME = 'ORANGE_THEME';

//RESET
export const RESET = 'RESET';

//Breadcrumb
export const ADD_NAVIGATION = "ADD_NAVIGATION";
export const REMOVE_NAVIGATION = "REMOVE_NAVIGATION";
export const RESET_NAVIGATION = 'RESET_NAVIGATION';

//Spinner
export const LOADING_SPINNER = "LOADING_SPINNER";

//User
export const ADD_USERDETAILS = "ADD_USERDETAILS";

//QuickSearch
export const ADD_ADVANCEDSEARCHDETAILS = "ADD_ADVANCEDSEARCHDETAILS";
export const SET_SEARCHTABPOSITION = "SET_SEARCHTABPOSITION";
export const SET_CURRENTSEARCHSAVED = "SET_CURRENTSEARCHSAVED";
export const QUICKSEARCH_RESET = "QUICKSEARCH_RESET";
export const ADD_SEARCHSTACKQUERY = "ADD_SEARCHSTACKQUERY";
export const REMOVE_SEARCHSTACKQUERY = "REMOVE_SEARCHSTACKQUERY";

//Report
export const ADD_ACCESSTOKEN = "ADD_ACCESSTOKEN";
export const RESET_ACCESSTOKEN = "RESET_ACCESSTOKEN";

//Facility
export const ADD_TEMPLATEDETAILS = "ADD_TEMPLATEDETAILS";
export const ADD_PIPELINETEMPLATEDETAILS = "ADD_PIPELINETEMPLATEDETAILS";
export const RESET_TEMPLATEDETAILS = "RESET_TEMPLATEDETAILS";
export const RESET_PIPELINETEMPLATEDETAILS = "RESET_PIPELINETEMPLATEDETAILS";

//SEARCHSTACK
export const RESET_QUERY = "RESET_QUERY";
export const ADD_QUERYID = 'ADD_QUERYID';

//LOCATION
export const ADD_LOCATION = 'ADD_LOCATION';
export const RESET_LOCATION = 'RESET_LOCATION';