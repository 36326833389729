import React from 'react';

import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Tabs, Tab, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import withRouter from '../../components/router/WithRouter';
import AlertNotificationComp from '../../components/notification/AlertNotificationComp';

//Analysis Components
import BasinPBComp from './BasinPBComp';
import OverallFacilitiesPBComp from './OverallFacilitiesPBComp';
import { useParams } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({


    searchcontrol: {
        // width: '98.8%',
        // margin: '10px',
        margin: 10,
        backgroundColor: theme.palette.background.paper,
    },
    sidebarRoot: {
        top: '10px',
    },
    searchinput: {
        flex: 1,
        // width: '90%',
    },
    iconButton: {
        padding: 10,
    },
    formControl: {
        // width: '100%',
        paddingLeft: '10px',
        paddingRight: '10px',
        height: '100%',
        borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
        borderRight: '1px solid rgba(0, 0, 0, 0.23)',
        '& .MuiInputBase-input': {
            // paddingTop: '15px',
        },
    },
    mapButton: {
        paddingLeft: '10px',
        paddingRight: '10px',
        borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
        borderRight: '1px solid rgba(0, 0, 0, 0.23)',
    },

    csvdownloadButton: {
        paddingLeft: '10px',
        paddingRight: '10px',
        borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
    },

    reportButton: {
        paddingLeft: '10px',
        paddingRight: '10px',

        borderRight: '1px solid rgba(0, 0, 0, 0.23)',
    },

    fullHeight: {
        height: '100%',
    },
    tablelayout: {
        margin: '10px',
    },
    csvdowloaddialog: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '950px',
            padding: 0,
            margin: 0,
        },
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),

        '& .MuiSvgIcon-root': {
            cursor: 'pointer',
        },
    },
    searchinfo: {
        marginTop: 10,
        marginBottom: 10,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && [children]}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

function AnalysisComp(props) {

    const classes = useStyles();


    const { type } = useParams();

    const analysis = JSON.parse(type);

    // console.log(analysis);

    const [status, setStatusBase] = React.useState({
        show: false,
        message: "",
        variant: "error",
    });

    const fnShowErrorMessage = () => setStatusBase({ show: false, message: "", variant: "info" });


    const [selectedanalysistab, setSelectedAnalysisTab] = React.useState(0);


    const handleChange = (event, newValue) => setSelectedAnalysisTab(newValue);


    const [searchinfo, setSearchInfo] = React.useState({
        name: '',
        description: '',
    });


    const fnFetchAnalysisComp = (key, value) => {

        switch (key) {
            case 'ccus':
                return (
                    <Grid container style={{ height: "800px" }}>
                        <Grid item xs={12}>
                            <OverallFacilitiesPBComp type={key}></OverallFacilitiesPBComp>
                        </Grid>
                    </Grid>

                );
            case 'hydrogenplant':
                return (
                    <Grid container style={{ height: "800px" }}>
                        <Grid item xs={12}>
                            <OverallFacilitiesPBComp type={key}></OverallFacilitiesPBComp>
                        </Grid>
                    </Grid>
                );
            case 'basin':
                return (
                    <Grid container style={{ height: "800px" }}>
                        <Grid item xs={12}>
                            <BasinPBComp type={key} values={value}></BasinPBComp>
                        </Grid>
                    </Grid>
                );
        }
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

                    <Tabs
                        value={selectedanalysistab}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="Scrollable Analysis Tabs"
                    >
                        {analysis.map((value, index) => {
                            return (<Tab label={value.name} {...a11yProps(index)} />)
                        })}

                    </Tabs>
                </Box>
                {analysis.map((value, index) => {
                    return (
                        <TabPanel value={selectedanalysistab} index={index}>
                            {/* {value.value} */}
                            {fnFetchAnalysisComp(value.type, value.value)}
                        </TabPanel>)
                })}
            </Box>




            <AlertNotificationComp
                variant={status.variant}
                onClose={fnShowErrorMessage}
                message={status.message}
                display={status.show}
            ></AlertNotificationComp>
        </>
    );
}

export default withRouter(AnalysisComp);
