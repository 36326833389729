import { RESET_QUERY, ADD_QUERYID, RESET_INITIAL_STATE } from '../constants';
let INIT_STATE = {
    id: null,
};

function SearchStackReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case RESET_QUERY:
            return {
                ...state,
                id: null

            };


        case ADD_QUERYID: {
            return {
                ...state,
                id: action.payload.id

            };
        }
        case RESET_INITIAL_STATE:
            return {
                ...INIT_STATE,
            };

        default:
            return state;
    }
}

export default SearchStackReducer;
