import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import NavigationReducer from './breadcrumb/NavigationReducer';
import SpinnerReducer from './spinner/SpinnerReducer';
import UserReducer from './user/UserReducer';
import QuickSearchReducer from './quicksearch/QuickSearchReducer';
import ReportReducer from './reports/ReportReducer';
import FacilityReducer from './facility/FacilityReducer';
import LocationReducer from './location/LocationReducer';
import SearchStackReducer from './searchstack/SearchStackReducer';

const RootReducers = combineReducers({
  CustomizerReducer,
  NavigationReducer,
  SpinnerReducer,
  UserReducer,
  QuickSearchReducer,
  ReportReducer,
  FacilityReducer,
  LocationReducer,
  SearchStackReducer
});

export default RootReducers;
