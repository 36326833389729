import { ADD_TEMPLATEDETAILS, ADD_PIPELINETEMPLATEDETAILS, RESET_TEMPLATEDETAILS, RESET_PIPELINETEMPLATEDETAILS, RESET_INITIAL_STATE } from '../constants';

let INIT_STATE = {
    templatevalues: null,
    pipelinetemplatevalues: null,
};

function FacilityReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case ADD_TEMPLATEDETAILS:
            return {
                ...state,
                templatevalues: action.payload.templatevalues
            };
        case ADD_PIPELINETEMPLATEDETAILS:
            return {
                ...state,
                pipelinetemplatevalues: action.payload.pipelinetemplatevalues
            };
        case RESET_TEMPLATEDETAILS:

            return {
                ...state,
                templatevalues: null
            };
        case RESET_PIPELINETEMPLATEDETAILS:
            return {
                ...state,
                templatevalues: null,
                pipelinetemplatevalues: null
            };
        case RESET_INITIAL_STATE:
            
            return {
                ...INIT_STATE,
            };
        default:
            return state;
    }
}

export default FacilityReducer;
