const typography = {
  fontFamily: "'DM Sans', sans-serif",
  body1: {
    fontWeight: 400,
    fontSize:'0.8rem'
  },
  h1: {
    fontWeight: 500,
    fontSize: '1.675rem',
    lineHeight: '1.5',
  },
  h2: {
    fontWeight: 500,
    fontSize: '1.3rem',
    lineHeight: '1.5',
  },
  h3: {
    fontWeight: 500,
    fontSize: '1.1125rem',
    lineHeight: '1.5',
  },
  h4: {
    fontWeight: 500,
    fontSize: '0.925rem',
    lineHeight: '1.5',
  },
  h5: {
    fontWeight: 500,
    fontSize: '0.8rem',
    lineHeight: '1.5',
  },
  h6: {
    fontWeight: 500,
    fontSize: '0.675rem',
    lineHeight: '1.5',
  },
  button: {
    textTransform: 'none',
    fontWeight: '400',
  },
  subtitle1: {
    fontSize: '0.8rem',
    fontWeight: '400',
  },
  subtitle2: {
    fontSize: '0.675rem',
    fontWeight: '400',
  },
  caption: {
    fontSize: '0.6rem',
    fontWeight: '400',
  },
};

export default typography;
