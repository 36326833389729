const ids = {
  gaspipeline: "f2405d8e-e848-4544-ac6c-6cffbbfc4c54",
  liquidpipeline: "d3695614-4d13-477c-b4a7-25752396d3b7",
  // ccus: "b9f4ee21-baf3-440b-8c62-325b5f322a97",
  // hydrogenplant: "343bb15e-af06-49a2-9a0f-097ee7805cce",
  ccus: "48f0e01c-4ec2-4b79-a209-d7a82ae03899",//online store
  hydrogenplant: "e8d9cee1-8691-4355-9933-07d99f98860c",//online store
  basin:"6149bd5d-ef45-4d9d-8952-26185e2fc19b",//"1efb97fc-fa6b-4539-a8d9-c5a87a1babe2"
  gasplant:"540cb164-790e-4a49-b6c9-c1ee57cc5b5a",
  pipeline:"68bf1a71-19ab-4386-957c-5dc92d63338b",
};

export const fnFetchPowerbiIds = type => ids[type];

const pbtargets = {
  gaspipeline: {
    table: "vpipelinesection",
    column: "PipelineName",
  },
  liquidpipeline: {
    table: "vPipelineHeader",
    column: "PipelineName",
  },
};

export const fnFetchPowerBiTarget = type => pbtargets[type];

