import { ADD_LOCATION, RESET_LOCATION } from '../constants';

let initialState = {
    lat: "",
    long: "",
    facility: "",
};

function LocationReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_LOCATION:
            return {
                ...state,
                lat: action.payload.lat,
                long: action.payload.long,
                facility: action.payload.facility
            };
        case RESET_LOCATION:
            return {
                ...state,
                lat: "",
                long: "",
                facility: ""
            };
        default:
            return state;
    }
}

export default LocationReducer;
